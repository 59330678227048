<template>
    <div>
        <v-card flat class="shadow mt-3">
            <!-- header -->
            <!-- header -->
            <div>
                <!-- filters -->
                <v-card flat class>
                    <v-expand-transition>
                        <div v-show="show">
                            <v-row class="align-center px-3">
                                <!-- <v-col cols="12" md="3">
                                    <v-autocomplete @input="getItems()" :items="provinces"
                                        v-model="provinceId" item-text="name" item-value="id" clearable dense
                                        placeholder="المحافظة" no-data-text="لا توجد بيانات" hide-details outlined />
                                </v-col> -->
                            </v-row>
                        </div>
                    </v-expand-transition>
                    <v-divider :class="show ? 'mt-3' : 'mt-1'" />
                </v-card>
                <v-row align="center" class="pa-3">
                    <v-col cols="12" md="2" class="mb-sm-1">
                        <v-btn @click="openAddDialog()" color="primary" class="btn">
                            <v-icon small class="pl-1">mdi-plus</v-icon>اضافة
                        </v-btn>
                    </v-col>
                    <v-spacer />
                    <!-- <v-col cols="12" md="4">
            <div class="d-flex justify-space-between">
              <v-text-field
                @input="getSliders()"
                v-model="filter.search"
                placeholder="ابحث هنا"
                hide-details
                dense
                class="pl-3"
                outlined
              />

            </div>
          </v-col> -->
                </v-row>
            </div>

            <!-- table -->
            <v-card flat tile>
                <v-data-table class="mytable" :items="sliders" :loading="loading" :headers="headers" :search="filter.search"
                    hide-default-footer loading-text="جاري تحميل البيانات" no-data-text="لا توجد بيانات"
                    :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'" :items-per-page="filter.pageSize">
                    <template v-slot:[`item.id`]="{ item }">
                        <h5>{{ sliders.indexOf(item) + 1 }}</h5>
                    </template>
                    <template v-slot:[`item.imageUrl`]="{ item }">
                        <img :src="item.imageUrl" height="100" width="150" alt="" class="pt-1" />
                    </template>

                    <template v-slot:[`item.createdAt`]="{ item }">
                        <span>{{ item.createdAt | formatDate }}</span>
                    </template>

                    <template v-slot:[`item.actions`]="{ item, index }">
                        <div style="min-width: 75px">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="deleteItem(item.id, index)" v-on="on" x-small icon depressed>
                                        <v-icon color="error">fi fi-rr-trash</v-icon>
                                    </v-btn>
                                </template>
                                <span>حذف</span>
                            </v-tooltip>
                        </div>
                    </template>
                </v-data-table>
            </v-card>
            <v-divider></v-divider>
            <div class="d-flex justify-space-between py-3">
                <v-spacer></v-spacer>
                <!-- <v-pagination
          v-model="filter.pageIndex"
          :length="length"
          :total-visible="7"
          @input="getSliders(filter.pageIndex, filter.pageSize, filter.search)"
        ></v-pagination> -->
            </div>
            <!-- <Pagination :items="sliders" /> -->
            <Dialog @refresh="getSliders()" />
        </v-card>
    </div>
</template>

<script>
export default {
    name: "Sliders",

    data() {
        return {
            show: false,

            headers: [

                {
                    text: " الصورة",
                    value: "imageUrl",
                },
                {
                    text: "تاريخ الإضافة",
                    value: "createdAt",
                },

                {
                    text: "الإجراءات",
                    value: "actions",
                    sortable: false,
                    align: "center",
                },
            ],
            sliders: [],
            uploading: false,
            loading: false,
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            length: 0,
            filter: {
                pageIndex: 1,
                pageSize: 15,
                search: "",
                count: 0,
            },
            pageSizes: [5, 10, 15, 30, 50, 100],
        };
    },

    components: {
        Dialog: () => import("./dialog"),
    },

    async mounted() {
        await this.getSliders(
            this.filter.pageIndex,
            this.filter.pageSize,
            this.filter.search
        );
        this.$eventBus.$on(`refresh`, async () => {
            await this.getSliders(
                this.filter.pageIndex,
                this.filter.pageSize,
                this.filter.search
            );
        });
    },

    beforeDestroy() {
        this.$eventBus.$off(`refresh`);
    },

    methods: {
        async getSliders() {
            this.loading = true;

            try {
                let query = `Sliders`;

                // if (pageIndex !== undefined && pageIndex !== null) {
                //   query += `&PageIndex=${pageIndex}`;
                // }

                // if (pageSize !== undefined && pageSize !== null) {
                //   query += `&PageSize=${pageSize}`;
                // }

                // if (search !== undefined && search !== null) {
                //   query += `&Search=${search}`;
                // }
                const res = await this.$http.get(query);
                this.sliders = res.data.result;
                // console.log(res.data.result);
            } catch (err) {
                this.dispatch("toastification/setToast", {
                    message: `${err.response.data.message}`,
                    type: "error",
                });
            } finally {
                this.loading = false;
            }
        },
        getItems() {
            this.$global.dispatch(`get${this.$route.meta.endPoint}`);
        },

        async deleteItem(id, i) {
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`${this.$route.meta.endPoint}`, id)
                        .then((res) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${res.data.message}`,
                                type: "success",
                            });
                            this.sliders.splice(i, 1);
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${err.data.message}`,
                                type: "error",
                            });
                        });
                }
            });
        },

        openAddDialog() {
            var item = { dialogType: "add" };
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
        },
    },
};
</script>

<style>
.atHover:hover {
    position: absolute;
    z-index: 10000;
    border-radius: 5%;
    animation: scalePic 0.5s linear forwards;
}

@keyframes scalePic {
    0% {
        transform: scale(0.8);
    }

    25% {
        transform: scale(1.3);
    }

    50% {
        transform: scale(1.8);
    }

    75% {
        transform: scale(2.3);
    }

    100% {
        transform: scale(2.8);
    }
}
</style>